import axios from 'axios';
import authHeader from "../../services/auth-header";
import ConstApiUrls from "../../services/const-api-urls";

const API_URL = ConstApiUrls.API_SERVICE_URL

class AccAccountService {
    getApiUrl() {
        return API_URL
    }

    getAllAccAccount(){
        return axios.get(API_URL + `getAllAccAccount`, { headers: authHeader() });
    }
    getAllAccAccountByCompany(){
        return axios.get(API_URL + `getAllAccAccountByCompany`, { headers: authHeader() });
    }

    getAllAccAccountContaining(page, pageSize, sortBy, order, search){
        return axios.get(API_URL + `getAllAccAccountContaining?page=${page}&pageSize=${pageSize}&sortBy=${sortBy}&order=${order}&search=${search}`, { headers: authHeader() });
    }
    getAccAccountById(id){
        return axios.get(API_URL + `getAccAccountById/${id}`, { headers: authHeader() });
    }
    getAllAccAccountByDivision(id){
        return axios.get(API_URL + `getAllAccAccountByDivision/${id}`, { headers: authHeader() });
    }

    updateAccAccount(item){
        return axios.put(API_URL + `updateAccAccount/${item.id}`, item, {headers: authHeader()})
    }
    createAccAccount(item){
        return axios.post(API_URL + `createAccAccount`, item, {headers: authHeader()})
    }
    deleteAccAccount(id){
        return axios.delete(API_URL + `deleteAccAccount/${id}`, {
            headers: authHeader()
        });
    }
    deleteAllAccAccount(itemIds){
        // let user = JSON.parse(localStorage.getItem('user'));
        // return axios.delete(API_URL + `deleteAllAccAccount`, {
        //     headers:  authHeaderMultipart(),
        //     data: {
        //         message: 'delete  success'
        //     }
        // });
        return axios.delete(API_URL + `deleteAllAccAccount`, {
            headers:  authHeader(),
            data: itemIds
        });


    }
}
export default new AccAccountService()