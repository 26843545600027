var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mx-4 elevation-10 rounded-lg pa-4"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"4","md":"4"}},[_c('div',{staticClass:"subtitle-1 font-weight-black green--text text--darken-2 ml-2"},[_vm._v("PRODUK TERLARIS")])]),_c('v-col',{attrs:{"cols":"12","md":"8","sm":"8"}})],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","sm":"4","md":"4"}},[_c('div',{staticClass:"ml-2 mt-4 mb-2"},[_c('v-date-picker',{staticClass:"rounded-lg",attrs:{"type":"month","color":"green","elevation":"10"},on:{"change":_vm.monthPickerChange},model:{value:(_vm.monthPicker),callback:function ($$v) {_vm.monthPicker=$$v},expression:"monthPicker"}})],1)]),_c('v-col',{attrs:{"cols":"12","sm":"8","md":"8"}},[_c('v-row',{staticClass:"mb-2",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('div',[_c('v-btn-toggle',{attrs:{"dense":"","color":"green"},model:{value:(_vm.toggleValue),callback:function ($$v) {_vm.toggleValue=$$v},expression:"toggleValue"}},[_c('v-btn',{attrs:{"value":"inQty"},on:{"click":function($event){return _vm.toggleClick('inQty')}}},[_c('span',{staticClass:"caption font-weight-bold"},[_vm._v("Quantity")])]),_c('v-btn',{attrs:{"value":"inValue"},on:{"click":function($event){return _vm.toggleClick('inValue')}}},[_c('span',{staticClass:"caption font-weight-bold"},[_vm._v("Value")])])],1)],1)]),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.itemsQtySales,"search":_vm.search,"page":_vm.currentPage,"dense":""},on:{"update:page":function($event){_vm.currentPage=$event},"click:row":_vm.rowTableClick},scopedSlots:_vm._u([{key:"item.number",fn:function(ref){
var index = ref.index;
return [_vm._v(" "+_vm._s((index + ((_vm.currentPage-1)*_vm.pageSize) +1 ))+" ")]}},{key:"item.description",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.description)+" "),_c('span',{staticClass:"grey--text ml-1 caption"},[_vm._v(_vm._s(item.shortDesc))])]}},{key:"item.sumResult",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-end mr-10"},[_c('span',{staticClass:"grey--text mr-2 caption font-weight-light"},[_vm._v(_vm._s(item.countResult)+" rows")]),_c('span',[_vm._v(_vm._s(_vm.formattedCurrencyValue(item.sumResult)))])])]}}],null,true)})],1)],1),_c('v-dialog',{attrs:{"width":"500px"},model:{value:(_vm.dialogShow),callback:function ($$v) {_vm.dialogShow=$$v},expression:"dialogShow"}},[_c('v-card',{staticClass:"pa-2"},[_c('v-card-subtitle',{staticClass:"my-1"},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(this.itemSelected.description))]),_c('span',{staticClass:"ml-2 grey--text font-weight-bold"},[_vm._v(_vm._s(this.itemSelected.shortDesc))])]),_c('v-card-text',[_c('div',[_c('v-data-table',{attrs:{"headers":_vm.headersProduct,"items":_vm.itemsProductLast3Month,"hide-default-footer":"","dense":""},scopedSlots:_vm._u([{key:"item.number",fn:function(ref){
var index = ref.index;
return [_vm._v(" "+_vm._s((index + ((_vm.currentPage-1)*_vm.pageSize) +1 ))+" ")]}}],null,true)})],1),_c('div',{staticClass:"blue-grey lighten-3 rounded mt-2 elevation-10"},[_c('v-sparkline',{staticClass:"mt-2",attrs:{"value":_vm.sprarkLineValue,"gradient":_vm.gradient,"smooth":_vm.radius || false,"padding":_vm.padding,"line-width":_vm.width,"stroke-linecap":_vm.lineCap,"gradient-direction":_vm.gradientDirection,"fill":_vm.fill,"type":_vm.type,"auto-line-width":_vm.autoLineWidth,"auto-draw":""},scopedSlots:_vm._u([{key:"label",fn:function(item){return [_vm._v(" "+_vm._s(item.value)+" ")]}}])})],1)]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"primary",attrs:{"small":"","rounded":""},on:{"click":_vm.dialogClose}},[_vm._v("Close")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }