<template>
  <div class="mx-4 elevation-10 rounded-lg pa-4" >
    <v-row>
      <v-col cols="12" sm="4" md="4">
        <div class="subtitle-1 font-weight-bold">TOP BUYERS</div>
        <div class="ml-2 mt-4">
            <v-date-picker
                v-model="monthPicker"
                type="month"
                @change="monthPickerChange"
                color="blue"
                elevation="10"
                class="rounded-lg"
            ></v-date-picker>
        </div>
      </v-col>
      <v-col cols="12" sm="8" md="8">
        <v-row no-gutters class="mb-2">
          <v-col cols="0" sm="6" md="6">
          </v-col>
          <v-col cols="12" sm="6" md="6">
            <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                hide-details
            ></v-text-field>
          </v-col>
        </v-row>
        <v-data-table
            :headers="headers"
            :items="itemsTopBuyers"
            :search="search"
            :page.sync="currentPage"
            dense
        >
          <template v-slot:[`item.number`]="{ index }">
            {{ (index + ((currentPage-1)*pageSize) +1 )}}
          </template>

          <template v-slot:[`item.description`]="{ item }">
            {{item.description}} <span class="grey--text ml-1 caption">{{item.shortDesc}}</span>
            <span class="grey--text ml-1 caption font-italic">{{item.remark1}}</span>
          </template>

          <template v-slot:[`item.sumResult`]="{ item }">
            <div class="text-end">
              <span class="grey--text caption font-weight-light mr-2">{{item.countResult}} rows</span>
              {{ formattedCurrencyValue(item.sumResult) }}
            </div>
          </template>

        </v-data-table>
      </v-col>
    </v-row>
    <v-row class="blue-grey lighten-3 rounded-lg">
      <v-col cols="12" sm="4" md="4">
      </v-col>
      <v-col cols="12" sm="3" md="3">
        <div class="yellow--text">
          TOP BUYER TOTAL
        </div>
        <div class="font-weight-bold">
          {{ formattedCurrencyValue(computedItemsTopBuyers) }}
        </div>
      </v-col>
      <v-col cols="12" sm="3" md="3">
        <div class="yellow--text">
          WITH ENDUSER PRICE
        </div>
        <div class="">
          {{ formattedCurrencyValue(computedItemsTotalEndUserByCustomer) }}
        </div>
      </v-col>
      <v-col cols="12" sm="2" md="2">
        <div  class="yellow--text">
          TOT DISC
        </div>
        <div class="">
          {{ ((computedItemsTotalEndUserByCustomer-computedItemsTopBuyers)/ computedItemsTotalEndUserByCustomer*100).toFixed(2) }} %
        </div>
      </v-col>


    </v-row>

  </div>
</template>

<script>
export default {
  name: "DashboardTopBuyers",
  props:{
    itemsTopBuyers:[],
    itemsTotalEndUserByCustomer: []
  },
  watch:{
    // monthPicker:{
    //   handler: function (value){
    //     console.log(JSON.stringify(value))
    //   }
    // }

  },
  data (){
    return {
      currentPage: 1,
      totalTablePages: 1,
      totalPaginationPages: 1,
      pageSize: 10,
      pageSizes: [10, 25, 50, 150, 500],

      monthPicker: new Date().toISOString().substr(0, 7),
      search : '',
      headers: [
        {
          text: 'No',
          value: 'number',
          width: '8%',
          sortable: false
        },
        { text: 'Nama Product', value: 'description', width:"60%", sortable: false },
        { text: 'Qty Sold', value: 'sumResult', sortable: false },
      ],

    }
  },

  methods:{
    monthPickerChange(value){

      this.$emit('eventFromDashboardTopBuyers', value)
    },
    formattedCurrencyValue(value){
      if (!value){return 0 }
      return parseFloat(Math.round(value)).toLocaleString()
    }
  },
  computed:{
    computedItemsTopBuyers(){
      let sum =0
      this.itemsTopBuyers.forEach(x=>
          sum += x.sumResult
      )
      return sum
    },
    computedItemsTotalEndUserByCustomer(){
      let sum =0
      this.itemsTotalEndUserByCustomer.forEach(x=>
          sum += x.sumResult
      )
      return sum
    }

  },
  mounted() {
    this.monthPickerChange(this.monthPicker)
  }
}
</script>

<style scoped>

</style>