var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mx-4 elevation-10 rounded-lg pa-2"},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","sm":"8","md":"8"}},[_c('v-data-table',{attrs:{"headers":_vm.headersSales,"items":_vm.computedSalesByDivision,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.shortDesc)+" ")]}},{key:"item.description",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"fill-height"},[_c('span',{staticClass:"grey--text mr-1"},[_vm._v(_vm._s(item.shortDesc))]),_vm._v(_vm._s(item.description)+" ")])]}},{key:"item.sumResult",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"fill-height"},[_c('div',{staticClass:"font-weight-bold text-end"},[_vm._v(" "+_vm._s(_vm.formattedCurrencyValue(item.sumResult))+" ")]),_c('div',{staticClass:"grey--text text-end caption"},[_vm._v(" avg"),_c('span',{staticClass:"ml-1"},[_vm._v(_vm._s(_vm.formattedCurrencyValue(item.salesAvg)))])])])]}},{key:"item.kasMasuk",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"fill-height"},[_vm._l((item.accAccountsDebit),function(itemDetil,index){return _c('div',{key:index},[_c('v-flex',{staticClass:"d-flex"},[_c('span',[_vm._v(" "+_vm._s(itemDetil.accountDesc)+" ")]),_c('v-spacer'),_c('span',{staticClass:"text-end"},[_vm._v(" "+_vm._s(_vm.formattedCurrencyValue(itemDetil.value))+" ")])],1)],1)}),_c('div',{staticClass:"font-weight-bold blue--text mt-1"},[_c('v-flex',{staticClass:"d-flex"},[_c('span',[_vm._v(" TOTAL ")]),_c('v-spacer'),_c('span',{staticClass:"text-end"},[_vm._v(" "+_vm._s(_vm.formattedCurrencyValue(item.accAccountsDebit.map( function (x) { return x.value; }).reduce(function (sumResult, nextValue) { return sumResult+nextValue; }, 0)))+" ")])],1)],1)],2)]}}],null,true)},[_c('template',{slot:"body.append"},[_c('tr',{staticClass:"pink--text"},[_c('th',{staticClass:"subtitle-2"},[_vm._v("Totals")]),_c('th',{staticClass:"subtitle-2  text-end"},[_vm._v(_vm._s(_vm.formattedCurrencyValue(_vm.sumFieldSales('sumResult'))))]),_c('th',{staticClass:"subtitle-2 text-end"},[_vm._v(_vm._s(_vm.formattedCurrencyValue(_vm.sumFieldJournal())))])])])],2)],1),_c('v-col',{attrs:{"cols":"12","sm":"4","md":"4"}},[_c('v-btn-toggle',{staticClass:"mb-2",attrs:{"dense":"","color":"blue"},model:{value:(_vm.toggleValueBiaya),callback:function ($$v) {_vm.toggleValueBiaya=$$v},expression:"toggleValueBiaya"}},[_c('v-btn',{attrs:{"value":"biayaTertinggi","small":""}},[_c('span',{staticClass:"caption font-weight-bold"},[_vm._v("Biaya Tertinggi")])])],1),_c('v-data-table',{attrs:{"headers":_vm.headersJournal,"items":_vm.computedJournal,"dense":""},scopedSlots:_vm._u([{key:"item.value",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"fill-height"},[_c('div',{staticClass:"text-end"},[_vm._v(" "+_vm._s(_vm.formattedCurrencyValue(item.value))+" ")])])]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }