<template>
  <div class="mx-4 elevation-10 rounded-lg pa-2" >
    <v-row dense>
      <v-col cols="12" sm="8" md="8">
        <v-data-table
            :headers="headersSales"
            :items="computedSalesByDivision"
            hide-default-footer
        >

          <template v-slot:[`item.id`]="{ item }">
            {{item.shortDesc}}
          </template>

          <template v-slot:[`item.description`]="{ item }">
            <div class="fill-height">
              <span class="grey--text mr-1">{{item.shortDesc}}</span>{{item.description}}
            </div>
          </template>

          <template v-slot:[`item.sumResult`]="{ item }">
            <div class="fill-height">
              <div class="font-weight-bold text-end">
                {{ formattedCurrencyValue(item.sumResult) }}
              </div>
              <div class="grey--text text-end caption">
                avg<span class="ml-1">{{formattedCurrencyValue(item.salesAvg)}}</span>
              </div>
            </div>
          </template>

          <template v-slot:[`item.kasMasuk`]="{ item }">
            <div class="fill-height">
              <div v-for="(itemDetil, index) in item.accAccountsDebit" :key="index">
                <v-flex class="d-flex" >
                  <span>
                    {{ itemDetil.accountDesc}}
                  </span>
                  <v-spacer></v-spacer>
                  <span class="text-end">
                    {{ formattedCurrencyValue(itemDetil.value) }}
                  </span>
                </v-flex>
              </div>
              <div class="font-weight-bold blue--text mt-1">
                <v-flex class="d-flex" >
                  <span>
                    TOTAL
                  </span>
                  <v-spacer></v-spacer>
                  <span class="text-end">
                    {{ formattedCurrencyValue(item.accAccountsDebit.map( x => x.value).reduce((sumResult, nextValue) => sumResult+nextValue, 0)) }}
                  </span>
                </v-flex>
              </div>
            </div>
          </template>

          <template slot="body.append">
            <tr class="pink--text">
              <th class="subtitle-2">Totals</th>
              <th class="subtitle-2  text-end">{{ formattedCurrencyValue(sumFieldSales('sumResult')) }}</th>
              <th class="subtitle-2 text-end">{{ formattedCurrencyValue(sumFieldJournal()) }}</th>
            </tr>
          </template>

        </v-data-table>
      </v-col>

      <v-col cols="12" sm="4" md="4">
        <v-btn-toggle
            v-model="toggleValueBiaya"
            dense
            class="mb-2"
            color="blue"
        >
          <v-btn value="biayaTertinggi" small>
            <span class="caption font-weight-bold">Biaya Tertinggi</span>
          </v-btn>

        </v-btn-toggle>

        <v-data-table
            :headers="headersJournal"
            :items="computedJournal"
            dense
        >
          <template v-slot:[`item.value`]="{ item }">
            <div class="fill-height">
              <div class="text-end">
                {{ formattedCurrencyValue(item.value) }}
              </div>
            </div>
          </template>

        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "DashboardFinanceReport",
  props:{
    salesByDivision:[],
    itemsAccAccount: [],
    itemsAccJournald: [],
    dateDiffFromRange: Number

  },
  data(){
    return{
      toggleValueBiaya: 'biayaTertinggi',
      headersSales: [
        { text: 'Cabang/Division', value: 'description', width:"25%", sortable: false },
        { text: 'Sales', value: 'sumResult', width:"25%", sortable: false },
        { text: 'Kas Masuk', value: 'kasMasuk', width:"45%", sortable: false },
        // { text: 'Kas Keluar', value: 'kasKeluar', width:"2%", sortable: false },
      ],
      headersJournal: [
        { text: 'Akun', value: 'accountDesc', width:"60%", sortable: false },
        { text: 'Nilai', value: 'value', width:"40%", sortable: false },
      ],

    }
  },
  computed:{
    computedSalesByDivision(){
      let indexJournal = 0
      let itemsTotalSalesAndCash = []

      this.salesByDivision.map( item => {
        try {
          item.dateDiff = this.dateDiffFromRange +1
          item.salesAvg = Math.round(item.sumResult / item.dateDiff)
        }catch (e) {
          e.toString()
        }

        let itemsSumJournald = []
        this.itemsAccAccount.filter(x => x.coaType =='CASH_BA' && x.postEdit==true).forEach( itemAccAccount => {

          let valuesRp = 0
          this.itemsAccJournald.filter(x => x.fdivisionBean==item.id && x.accAccountBean==itemAccAccount.id).forEach( itemAccJourald =>{
            valuesRp += itemAccJourald.amountDebit
          })

          let newItem = {id: indexJournal++, fdivisionBean: 0, accountId: itemAccAccount.id, accountDesc: itemAccAccount.description, value: valuesRp}

          if (newItem.value !=0){
            itemsSumJournald.push(newItem)
          }
        })

        item.accAccountsDebit = itemsSumJournald

        itemsTotalSalesAndCash.push(item)

      })

      return itemsTotalSalesAndCash
    },

    computedJournal(){
      let indexJournal = 0

      // console.log(JSON.stringify(this.itemsAccAccount))

      let itemsSumJournald = []
      this.itemsAccAccount.filter(x => x.coaType =='EXPENSE' && x.postEdit==true).forEach( itemAccAccount => {

        let valuesRp = 0
        this.itemsAccJournald.filter(x => x.accAccountBean==itemAccAccount.id).forEach( itemAccJourald =>{
          valuesRp += itemAccJourald.amountDebit
        })

        let newItem = {id: indexJournal++, accountId: itemAccAccount.id, accountDesc: itemAccAccount.description, value: valuesRp}

        if (newItem.value !=0){
          itemsSumJournald.push(newItem)
        }
      })

      return itemsSumJournald.sort((a, b) => (a.value > b.value)? -1: 1)
    }


  },
  methods:{
    formattedCurrencyValue(value){
      if (!value){return 0 }
      return parseFloat(value).toLocaleString()
    },
    sumFieldSales(key) {
      // sum data in give key (property)
      return this.salesByDivision.reduce((a, b) => a + (b[key] || 0), 0)
    },

    sumFieldJournal() {
      // sum data in give key (property)
      let totalValue = 0
      this.salesByDivision.forEach(item => {
          totalValue += item.accAccountsDebit.map( x=>x.value).reduce((sumResult, nextValue) => sumResult+nextValue, 0)
      })
      return totalValue
    },

  }
}
</script>

<style scoped>

</style>